@import "react-day-picker/lib/style.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:wght@400;500;700&display=swap');

@tailwind base;

@tailwind components;

.btn {
  @apply flex items-center rounded-lg py-2 px-4 text-sm border-2 border-transparent font-medium transition-all;
}

.btn:disabled {
  @apply opacity-60 cursor-not-allowed;
}

.btn:focus {
  @apply outline-none ring-2 ring-offset-2
}

.btn--small {
  @apply py-1 px-2 text-xs rounded-md;
}

.btn--primary {
  @apply bg-primary-500 text-white;
}

.btn--primary:focus {
  @apply ring-primary-500;
}

.btn--primary:hover:not([disabled]) {
  @apply bg-primary-700;
}

.btn--secondary {
  @apply bg-secondary-500 text-white;
}

.btn--secondary:focus {
  @apply ring-secondary-500;
}

.btn--secondary:hover:not([disabled]) {
  @apply bg-secondary-700;
}

.btn--tertiary {
  @apply bg-transparent text-tertiary-500 border-tertiary-500;
}

.btn--tertiary:focus {
  @apply ring-tertiary-500;
}

.btn--tertiary:hover:not([disabled]) {
  @apply text-tertiary-700 border-tertiary-700;
}

.btn--destructive {
  @apply bg-red-700 text-white;
}

.btn--destructive:focus {
  @apply ring-red-700;
}

.btn--destructive:hover:not([disabled]) {
  @apply bg-red-800;
}

.link {
  @apply text-secondary-500 border-b border-transparent transition-all cursor-pointer;
}

.link:hover {
  @apply text-secondary-700 border-secondary-700;
}

.link--destructive {
  @apply text-red-600;
}

.link--destructive:hover {
  @apply text-red-700 border-red-700;
}

.label {
  @apply block text-sm text-gray-700;
}

.input {
  @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 transition-all;
}

.input:hover {
  @apply border-gray-400;
}

.input:focus {
  @apply outline-none ring-secondary-500 border-secondary-500;
}

.input:disabled {
  @apply bg-gray-100;
}

.react-select__control {
  @apply shadow-sm;
}

.DayPicker-Day {
  display: table-cell;
  text-align: center;
  cursor: pointer;
  @apply rounded-full p-2 w-10 h-10 align-middle;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  @apply bg-secondary-500 text-white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  @apply bg-secondary-500 bg-opacity-60;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  @apply bg-secondary-500 bg-opacity-20;
}

.DayPicker-Day--today {
  @apply font-medium bg-secondary-100 text-secondary-300;
}

.DayPickerInput-OverlayWrapper {
  position: absolute;
  min-width: 440px;
  z-index: 900;
}

@screen sm {
  .input {
    @apply text-sm;
  }

  .react-select__control {
    @apply text-sm;
  }
}

/* Basic editor styles */
/* .ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
} */

.ProseMirror p.is-editor-empty:first-child::before {
  @apply text-gray-300;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@tailwind utilities;
